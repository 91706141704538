import React from "react";
import "antd/dist/antd.css";
import "./css/style.css";
import { BrowserRouter, HashRouter, Route, Redirect } from "react-router-dom";

import { FileContext } from "./Context";

import Home from "./pages/Home";
import SubDomain from "./components/SubDomain";
import FileShare from "./components/FileShare";
import SharedFiles from "./components/SharedFiles";
import Trash from "./components/Trash";
import Bookmarks from "./components/Bookmarks";
import ImageViewer from "./components/Viewer";
import CreateCourse from "./components/CreateCourse";
import ManageCourse from "./components/ManageCourse";

import TextEditor from "./pages/TextEditor";

import { useState, useEffect } from "react";
import axios from "axios";
import SplashPage from "./pages/SplashPage";
import { message } from "antd";
import Loader from "react-loader-spinner";
import Spaces from "./components/Spaces";
import CryptoJS from "crypto-js";
import CreatePostVideo from "./components/CreatePostVideo";
import Login from "./pages/Login";
import fetch from "./fetchurl.js";
import io from "socket.io-client";
// import SideNavigation from "../FixedNavigation/FixedNavigation";

let url;
if ("test1" === "test") {
  url = "http://localhost:3232/";
  // url = "https://devstagebrainb.pulse.stream/";
} else {
  // url = "https://brain.gxnitrous.com/";
  url = "https://drivetest.globalxchange.io/";
}

//"http://localhost:3237"
const socket = io(
  "test1" === "test"
    ? "https://devstagebrains.pulse.stream"
    : "https://drivetestsock.pulse.stream",
  {
    extraHeaders: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("user_account"),
      // inf: "6ed22891bac6a1abd3f277c8cc97f170",
      // mbid: "13d88b72d05effdc99a79bfe6c8ca589",
      // mbuserid: "d9d575a688b5db6ed62632bde99bf4e3",
    },
  }
);

socket.request = function request(type, data = {}) {
  return new Promise((resolve, reject) => {
    socket.emit(type, data, (data) => {
      if (!data.status) {
        resolve(data);
      } else {
        resolve(data);
      }
    });
  });
};

const chunk_size = 2000000;

function App() {
  const [path, setPath] = useState("root/");
  const [pathArray, setPathArray] = useState(["root"]);
  const [fileFolderData, setfileFolderData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [totalUsedSize, setTotalUsedSize] = useState([]);
  const [categoryPercentage, setCategoryPercentage] = useState([]);
  const [registerFlag, setRegisterFlag] = useState(null);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");
  const [gxUserEmails, setGxUserEmails] = useState([]);
  const [spaceUsedObject, setSpaceUsedObject] = useState(null);
  const [maxStorageLimit, setMaxStorageLimit] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [pulseTradingFloorApiUrl, setpulseTradingFloorApiUrl] = useState(
    "test1" === "test"
      ? "http://localhost:3997/"
      : "https://pulseapi.globalxchange.io/"
  );
  // const [profiledVideoUrl, setProfiledVideoUrl] = useState(
  //   "http://localhost:4197/"
  // );
  const [profiledVideoUrl, setProfiledVideoUrl] = useState(
    "https://vod-backend.globalxchange.io/"
  );
  const value = {
    fileFolderData,
    path,
    setPath,
    fileFolderData,
    setfileFolderData,
    pathArray,
    setPathArray,
    url,
    storageData,
    setStorageData,
    totalUsedSize,
    setTotalUsedSize,
    categoryPercentage,
    setCategoryPercentage,
    registerFlag,
    setRegisterFlag,
    email,
    setEmail,
    token,
    setToken,
    username,
    setUsername,
    gxUserEmails,
    setGxUserEmails,
    spaceUsedObject,
    setSpaceUsedObject,
    maxStorageLimit,
    setMaxStorageLimit,
    pulseTradingFloorApiUrl,
    profiledVideoUrl,
    setProfiledVideoUrl,
    socket,
    chunk_size,
  };

  useEffect(() => {
    const handleRedirect = async () => {
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("token").length > 0
      ) {
        // console.log("Token Valid...");
        // console.log("the url is", fetch.url);
        var dd = await axios.post(fetch.url + "brokerage/verify_token", {
          token: localStorage.getItem("token"),
          userpoolid: "us-east-2_ALARK1RAa",
        });
        // console.log("ddd", dd);
        var user;
        if (dd.data) user = dd.data;
        else user = null;

        if (user !== null && Date.now() < user.exp * 1000) {
          // console.log("Session valid!!");
          userchk();
        } else {
          console.log("nulling");
          localStorage.setItem("token", "");
          setRedirect(true);
          localStorage.clear();
          window.location.reload();
        }
      } else {
        console.log("else block token null");
        setRedirect(true);
      }
    };
    handleRedirect();
  }, []);

  const userchk = () => {
    let email_from_storage = localStorage.getItem("user_account");
    setEmail(email_from_storage);
    let token_from_storage = localStorage.getItem("token");
    setToken(token_from_storage);
    let username_from_storage = localStorage.getItem("uusseerrname");
    setUsername(username_from_storage);

    axios
      .post(`${url}account/registration-check`, {
        email: email_from_storage,
        token: token_from_storage,
      })
      .then((res) => {
        if (res.data.status) {
          setRegisterFlag(res.data.payload);
          if (res.data.payload) {
            axios
              .post(
                url + "folder/list-contents",
                {
                  email: email_from_storage,
                  token: token_from_storage,
                }
                // {
                //   headers: {
                //     inf: "6ed22891bac6a1abd3f277c8cc97f170",
                //     mbid: "13d88b72d05effdc99a79bfe6c8ca589",
                //     mbuserid: "6f794dd81e96b34539027ead48e8f32f",
                //   },
                // }
              )
              .then((res) => {
                let all_data = [...res.data];
                setStorageData([...all_data[all_data.length - 1]]);
                all_data.pop();
                setfileFolderData([...all_data]);
              });
            // axios
            //   .get("https://comms.globalxchange.com/listUsernames")
            //   .then((res) => {
            //     var bytes = CryptoJS.Rabbit.decrypt(
            //       res.data.payload,
            //       "gmBuuQ6Er8XqYBd"
            //     );
            //     var originalText = JSON.parse(
            //       bytes.toString(CryptoJS.enc.Utf8)
            //     );
            //     // console.log("textojqdwo", originalText);
            //     setGxUserEmails(originalText.emails);
            //   });

            axios
              .post(url + "folder/total-size", {
                email: email_from_storage,
                token: token_from_storage,
              })
              .then((res) => {
                // console.log(res.data.payload);
                setSpaceUsedObject({ ...res.data.payload });
              });

            axios
              .post(url + "account/storage-limit", {
                email: email_from_storage,
                token: token_from_storage,
              })
              .then((res) => {
                // console.log(res.data.payload);
                setMaxStorageLimit(res.data.payload * 10 ** 9);
              });
          }
        }
      });
  };

  useEffect(() => {
    conditionalView();
  }, [localStorage.getItem("access_token")]);

  const conditionalView = () => {
    if (localStorage.getItem("access_token")) {
      return (
        <FileContext.Provider value={value}>
          {/* {redirect ? logout : ""} */}
          <HashRouter basename="/">
            {/* <Nav /> */}
            {localStorage.getItem("access_token") ? (
              <>
                <Route exact path="/" component={Home} />
                <Route exact path="/share" component={FileShare} />
                <Route exact path="/shared-files" component={SharedFiles} />
                <Route exact path="/subdomain" component={SubDomain} />
                <Route exact path="/trash" component={Trash} />
                <Route exact path="/bookmark" component={Bookmarks} />
                <Route exact path="/spaces" component={Spaces} />
                <Route exact path="/viewer" component={ImageViewer} />
                <Route exact path="/createCourse" component={CreateCourse} />
                <Route exact path="/managecourse" component={ManageCourse} />
                <Route
                  exact
                  path="/createPostVideo"
                  component={CreatePostVideo}
                />
              </>
            ) : (
                <Route exact path="/" component={SplashPage} />
              )}

            {/* <Route exact path="/editor" component={TextEditor} />
            <Route exact path="/home/:id" component={InFolder} /> */}
          </HashRouter>
        </FileContext.Provider>
      );
    } else {
      return <Login />;
    }
  };

  return <>{conditionalView()}</>;
}

export default App;
