import React, { useContext, useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { FileContext } from "../Context";
import { Icon, Divider, Alert } from "antd";

const InfoSidebar = () => {
  const value = useContext(FileContext);
  const [data, setData] = useState([]);
  console.log(value.categoryPercentage);
  const [COLORS, setColors] = useState([
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#795548",
    "#FBC02D",
    "#E64A19",
    "#9C27B0",
    "#2E7D32",
    "red"
  ]);

  // useEffect(() => {
  //   if (value.spaceUsedObject !== null) {
  //     value.setTotalUsedSize(
  //       (value.spaceUsedObject.storage_size +
  //         value.spaceUsedObject.trash_size) /
  //         10 ** 9
  //     );
  //   }

  //   // console.log(((total / 10 ** 6) * 100) / 10000);
  //   // console.log(((total / 10 ** 9) * 100) / 10);
  //   // console.log(value.storageData);
  // }, [value.spaceUsedObject]);

  useEffect(() => {
    let tempData = [];
    value.storageData.map(item => {
      tempData.push({
        name: item.type,
        value: Number(
          (((item.size / 10 ** 9) * 100) / value.totalUsedSize).toFixed(2)
        )
      });
    });
    if (value.spaceUsedObject !== null) {
      tempData.push({
        name: "trash",
        value: Number(
          (
            ((value.spaceUsedObject.trash_size / 10 ** 9) * 100) /
            value.totalUsedSize
          ).toFixed(2)
        )
      });
      console.log(tempData);
      setData([...tempData]);
    }
  }, []);

  // const getIcon = item => {
  //   switch (item) {
  //     case "image":
  //       return (
  //         <Icon
  //           type="file-image"
  //           style={{ fontSize: "18px", color: "#0088FE" }}
  //         />
  //       );
  //     case "video":
  //       return (
  //         <Icon
  //           type="video-camera"
  //           style={{ fontSize: "20px", color: "#00C49F" }}
  //         />
  //       );
  //     case "pdf":
  //       return (
  //         <Icon
  //           type="file-pdf"
  //           style={{ fontSize: "20px", color: "#FFBB28" }}
  //         />
  //       );
  //     case "excel":
  //       return (
  //         <Icon
  //           type="file-excel"
  //           style={{ fontSize: "18px", color: "#056730" }}
  //         />
  //       );
  //     case "doc":
  //       return (
  //         <Icon
  //           type="file-word"
  //           style={{ fontSize: "18px", color: "#2B7CD3" }}
  //         />
  //       );
  //     case "powerpoint":
  //       return (
  //         <Icon type="file-ppt" style={{ fontSize: "18px", color: "red" }} />
  //       );
  //     case "unknown":
  //       return (
  //         <Icon
  //           type="file-unknown"
  //           style={{ fontSize: "18px", color: "black" }}
  //         />
  //       );
  //     case "compressed":
  //       return (
  //         <Icon
  //           type="file-zip"
  //           style={{ fontSize: "18px", color: "#F6E095" }}
  //         />
  //       );
  //     case "audio":
  //       return (
  //         <Icon type="audio" style={{ fontSize: "18px", color: "#0088FE" }} />
  //       );
  //     default:
  //       return "foo";
  //   }
  // };

  return (
    <>
      {/* Title Div */}
      <div
        style={{
          textAlign: "center",
          //   backgroundColor: "lightgray",
          padding: "10px 0px"
        }}
      >
        <span
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#405EC2"
          }}
        >
          GX Drive
        </span>
      </div>
      {/* Pie Chart Div */}

      <div
        style={{
          width: "100%",
          height: "200px",
          textAlign: "center"
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* storage distribution among different files div */}
      <div>
        {value.storageData.map(item => {
          return (
            <>
              <Divider dashed style={{ margin: "20px 0px" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 20px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={require(`../img/file-types/${item.type}.png`)}
                    width="25px"
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px"
                    }}
                  >
                    {item.type.toUpperCase()} FILES
                  </span>
                </div>
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {(item.size / 10 ** 9).toFixed(2)} GB{" "}
                  <span style={{ color: "lightgray" }}>
                    |{" "}
                    {(
                      ((item.size / 10 ** 9) * 100) /
                      value.totalUsedSize
                    ).toFixed(2)}
                    %
                  </span>
                </span>
              </div>
            </>
          );
        })}
        <div>
          <Divider dashed style={{ margin: "20px 0px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 20px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <img
                src={require(`../img/file-types/trash.png`)}
                width="35px"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "14px"
                }}
              >
                TRASH FILES
              </span>
            </div>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              {(value.spaceUsedObject.trash_size / 10 ** 9).toFixed(2)} GB{" "}
              <span style={{ color: "lightgray" }}>
                |{" "}
                {(
                  ((value.spaceUsedObject.trash_size / 10 ** 9) * 100) /
                  value.totalUsedSize
                ).toFixed(2)}
                %
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* Info about storage */}
      <Alert
        style={{ marginTop: "40px", borderStyle: "none" }}
        message={
          <div style={{ padding: "10px 30px", fontSize: "15px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Total Storage Available:</div>
              <div style={{ float: "right", fontWeight: "bold" }}>
                {value.maxStorageLimit / 10 ** 9} GB
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Total Storage Used</div>
              <div style={{ float: "right", fontWeight: "bold" }}>
                {value.totalUsedSize.toFixed(2)} GB
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Total Storage Remaining:</div>
              <div style={{ float: "right", fontWeight: "bold" }}>
                {(
                  value.maxStorageLimit / 10 ** 9 -
                  value.totalUsedSize
                ).toFixed(2)}{" "}
                GB
              </div>
            </div>
          </div>
        }
      />
      <br />
      <br />
    </>
  );
};

export default InfoSidebar;
