import React, { useState } from "react";
import { Row, Col, Button, Icon, Drawer } from "antd";
import ContentArea from "../components/ContentArea";
import Sidebar from "../components/Sidebar";
const NavMobile = () => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          backgroundColor: "#1D87D0",
          alignItems: "center"
        }}
      >
        <Col span={20} style={{ display: "flex", alignItems: "center" }}>
          <img src={require("../img/gx.png")} alt="" width="30px" />{" "}
          &nbsp;&nbsp;
          <span
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "white"
            }}
          >
            GX Drive
          </span>
        </Col>
        <Col
          onClick={e => setVisibleMenu(true)}
          span={4}
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            color: "white"
          }}
        >
          <Icon type="menu" />
        </Col>
      </Row>
      <Drawer
        width={window.innerWidth}
        bodyStyle={{ padding: "0px" }}
        placement="right"
        closable={true}
        onClose={e => setVisibleMenu(false)}
        visible={visibleMenu}
      >
        <Sidebar />
      </Drawer>
    </>
  );
};

export default NavMobile;
