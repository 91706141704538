import React from "react";
import { Row, Col, Button, Icon, Drawer } from "antd";
import ContentArea from "../components/ContentArea";
import Sidebar from "../components/Sidebar";
import TextEditor from "./TextEditor";
import { useState } from "react";
import NavMobile from "../components/NavMobile";

const Home = () => {
  return (
    <>
      {window.innerWidth > 600 ? (
        <Row style={{ minHeight: "100vh", position: "relative" }}>
          <Col
            xs={24}
            sm={4}
            style={{
              backgroundColor: "white",
              position: "fixed",
              left: 0,
              top: 0,
              height: "100vh",
            }}
          >
            <Sidebar />
          </Col>
          <Col
            xs={24}
            sm={20}
            offset={window.innerWidth > 600 ? 4 : 0}
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              style={{ padding: "30px", height: window.innerHeight }}
              className="scroll-style"
            >
              <ContentArea />
              {/* <TextEditor /> */}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <NavMobile />
          <div
            style={{ padding: "30px", height: window.innerHeight }}
            // className="scroll-style"
          >
            <ContentArea />
            {/* <TextEditor /> */}
          </div>
        </>
      )}
    </>
  );
};

export default Home;
