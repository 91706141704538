import React from "react";
import Sidebar from "./Sidebar";
import NavMobile from "./NavMobile";

import { Row, Col } from "antd";

const Spaces = () => {
  return (
    <>
      <Row>
        {window.innerWidth > 600 ? (
          <Col
            //   className="shadow"
            //   height="100vh"
            xs={24}
            sm={4}
            style={{ backgroundColor: "white" }}
          >
            <Sidebar />
          </Col>
        ) : (
          <NavMobile />
        )}
        <Col xs={24} sm={20}>
          <Row
            style={{
              height: window.innerHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <h1 style={{ margin: "0px" }}>SPACES FOR YOU</h1>
                <h6 style={{ float: "right" }}>Coming Soon</h6>
                <br />
                <br />
                <ul style={{ fontSize: "20px" }}>
                  <li>Collaborative Documents Editing</li>
                  <li>File Version Control</li>
                  <li>Group File Sharing</li>
                </ul>
              </div>
            </Col>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ marginRight: "10px" }}>
                <img src={require("../img/spaces.svg")} alt="" width="100%" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Spaces;
