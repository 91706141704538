import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Badge,
  Result,
  Avatar,
  Modal,
  message,
  Typography,
  Tooltip,
} from "antd";

import { Link } from "react-router-dom";
import { FileContext } from "../Context";
import Sidebar from "./Sidebar";
import NavMobile from "./NavMobile";
import axios from "axios";

const { Meta } = Card;
const { Paragraph } = Typography;

const Bookmarks = () => {
  const value = useContext(FileContext);

  const [link, setLink] = useState("");
  const [linkList, setLinkList] = useState([]);
  const [newBookmark, setNewBookmark] = useState("");
  const [visibleAddBookmark, setVisibleAddBookmark] = useState(false);

  useEffect(() => {
    axios
      .post(value.url + "bookmark/user-bookmarks", {
        email: value.email,
        token: value.token,
      })
      .then((res) => {
        if (res.data.status) {
          setLinkList([...res.data.payload]);
        } else {
          message.destroy();
          message.error("Fetcching Bookmarks Failed", 3);
        }
      });
  }, []);

  const handleSubmit = (e) => {
    let temp = [...linkList];
    temp = linkList.filter((iterator) => {
      return iterator.url === link;
    });

    if (temp.length == 0) {
      message.destroy();
      message.loading("Saving new link...", 0);
      e.preventDefault();

      axios
        .post(value.url + "bookmark/add-bookmark", {
          email: value.email,
          token: value.token,
          url: link,
        })
        .then((res) => {
          if (res.data.status) {
            message.destroy();
            message.success("Bookmark Save Success", 3);
            setLinkList([...linkList, { ...res.data.payload }]);
            setLink("");
          } else {
            message.destroy();
            message.error("Bookmark Save Failed", 3);
          }
        });
    } else {
      message.destroy();
      message.warning("Bookmark already exists", 5);
      setLink("");
    }
  };
  const handleRemove = (index) => {
    const newLinkList = linkList.filter((_, i) => i !== index);
    setLinkList(newLinkList);
  };

  const removeBookmarkHandler = (item) => {
    message.destroy();
    message.loading("Deleting Bookmark", 0);
    axios
      .post(value.url + "bookmark/delete-bookmark", {
        email: value.email,
        token: value.token,
        url: item.url,
      })
      .then((res) => {
        if (res.data.status) {
          message.destroy();
          message.success("Deleting Bookmark Success", 3);
          let temp = [...linkList];
          console.log(temp);
          temp = temp.filter((iterator) => {
            return iterator.url !== item.url;
          });
          console.log(temp);
          setLinkList([...temp]);
        } else {
          message.destroy();
          message.success("Deleting Bookmark Failed", 3);
        }
      });
  };

  return (
    <>
      <Row style={{ minHeight: "100vh", position: "relative" }}>
        {window.innerWidth > 600 ? (
          <Col
            //   className="shadow"
            //   height="100vh"
            xs={24}
            sm={4}
            style={{
              backgroundColor: "white",
              position: "fixed",
              left: 0,
              top: 0,
              height: "100vh",
            }}
          >
            <Sidebar />
          </Col>
        ) : (
          <NavMobile />
        )}
        <Col
          xs={24}
          sm={20}
          offset={window.innerWidth > 600 ? 4 : 0}
          style={{
            backgroundColor: "#FFFFFF",
            height: window.innerHeight,
          }}
        >
          <div style={{ padding: "30px" }}>
            <div style={{ marginTop: "-10px" }}>
              <div
                style={{
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                <i
                  class="fa fa-bookmark"
                  style={{
                    fontSize: "28px",
                    marginLeft: "-2px",
                  }}
                ></i>
                &nbsp;&nbsp;Bookmarked Links
              </div>

              {/* <Divider style={{ margin: "0px", marginTop: "23px" }} /> */}
            </div>
            <br />
            <div>
              <Form style={{ display: "flex" }} onSubmit={handleSubmit}>
                <Input
                  placeholder="Paste your link here..."
                  type="text"
                  size="large"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
                &nbsp;&nbsp;&nbsp;
                <Button type="primary" size="large" onClick={handleSubmit}>
                  <i class="fa fa-bookmark" style={{}}></i>
                  &nbsp; Bookmark
                </Button>
              </Form>
            </div>
            <br />
            <div
              className="scroll-style"
              style={{ height: "73vh", padding: "10px" }}
            >
              {linkList.length > 0 ? (
                linkList.map((item, i) => {
                  return (
                    <>
                      <Card
                        bodyStyle={{ padding: "14px", paddingBottom: "6px" }}
                        className="shadow-sm"
                        style={{
                          borderRadius: "8px",
                          border: "none",
                          marginTop: "8px",
                          marginBottom: "10px",
                        }}
                      >
                        <Row>
                          <Col span={20}>
                            <Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  size={70}
                                  src={
                                    item.image_url.length > 0
                                      ? item.image_url
                                      : require("../img/bookmark2.svg")
                                  }
                                />
                              }
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "-20px",
                                  }}
                                >
                                  {item.title}&nbsp;&nbsp;
                                  <Paragraph
                                    copyable={{ text: item.url }}
                                  ></Paragraph>
                                </div>
                              }
                              description={item.description}
                            />
                          </Col>
                          <Col span={1}></Col>
                          <Col
                            span={3}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              fontSize: "20px",
                              alignItems: "center",
                              height: "80px",
                            }}
                          >
                            <div>
                              <abbr title="Visit Link">
                                <a href={item.url} target="_blank">
                                  <i
                                    class="fa fa-share"
                                    style={{ color: "#D5D7EB" }}
                                  ></i>
                                </a>
                              </abbr>
                            </div>

                            <div>
                              <abbr title="Delete Bookmark">
                                <i
                                  onClick={(e) => removeBookmarkHandler(item)}
                                  class="fa fa-trash"
                                  style={{
                                    color: "#D5D7EB",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </abbr>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      {/* <Divider dashed style={{ margin: "0px 0px" }} /> */}
                    </>
                  );
                })
              ) : (
                <Result status="404" title="No Bookmarked Links" />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Bookmarks;
