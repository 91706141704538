import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Icon,
  Card,
  Divider,
  Alert,
  Menu,
  Dropdown,
  Modal,
  Button,
  message,
  Popover,
  Tooltip,
  Progress,
  Empty,
  Typography,
  Tabs,
  Result,
} from "antd";
import { useDropzone } from "react-dropzone";

import { FileContext } from "../Context";
import Sidebar from "./Sidebar";
import FileDownload from "js-file-download";
import Loader from "react-loader-spinner";

import axios from "axios";
import NavMobile from "./NavMobile";

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const SharedFiles = () => {
  const value = useContext(FileContext);
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    noKeyboard: true,
  });

  const [rootFolderList, setRootFolderList] = useState([]);

  const [visibleCreateFolder, setVisibleCreateFolder] = useState(false);
  const [visibleRenameFolder, setVisibleRenameFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [renameFolderName, setRenameFolderName] = useState("");
  const [currentFocusFolder, setCurrentFocusFolder] = useState({});
  const [query, setQuery] = useState("");
  const [visibleUpload, setVisibleUpload] = useState(false);

  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadProgressVisible, setDownloadProgressVisible] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [downloadingFile, setDownloadingFile] = useState("");
  const [downloadFinished, setDownloadFinished] = useState(false);
  const [zipDownload, setZipDownload] = useState(false);

  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);

  const [visibleShareFile, setVisibleShareFile] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const [currentFileAccessList, setcurrentFileAccessList] = useState([]);
  const [fileRecipient, setFileRecipient] = useState("");
  const [currentShareableLink, setCurrentShareableLink] = useState("");

  const [shareableGxLink, setShareableGxLink] = useState("");
  const [shareablePublicLink, setShareablePublicLink] = useState("");

  const [userSharedFiles, setUserSharedFiles] = useState([]);

  let filteredFiles = value.fileFolderData.filter((item) => {
    const lowquery = query.toLowerCase();
    return item.name.toLowerCase().indexOf(lowquery) >= 0 && !item.folder;
  });

  useEffect(() => {
    console.log(value.url);
    axios
      .post(value.url + "file/user-trash", {
        //username: "karthik",
        email: value.email,
        token: value.token,
      })
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.payload);
          setUserSharedFiles([...res.data.payload]);
        }
      });
  }, []);
  const removeFile = (file) => () => {
    console.log("removeFile...");
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    console.log(acceptedFiles);
  };

  const removeAll = () => {
    console.log("removeAll...");
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = "";
    console.log(acceptedFiles);
  };

  const files = acceptedFiles.map((file) => (
    <p key={file.path} style={{ marginBottom: "5px" }}>
      <span style={{ fontWeight: "bold" }}>{file.path}</span> &nbsp;- &nbsp;
      {(file.size / 10 ** 6).toFixed(3)} MB &nbsp;&nbsp;
      {/* <Icon
        type="delete"
        style={{ color: "red" }}
        onClick={removeFile(file)}
      ></Icon> */}
    </p>
  ));

  const downloadClick = (item) => {
    console.log(item);
    setDownloadProgressVisible(true);
    setDownloadingFile(item.name);

    axios({
      method: "get",
      url: value.url + "file/download-file",
      responseType: "blob",
      params: {
        path: item.path,
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: item.name,
      },
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTotalFileSize(progressEvent.total / 10 ** 6);
        setDownloadProgress(percentCompleted);
        // console.log(progressEvent.lengthComputable);
        console.log(percentCompleted);
      },
    })
      .then((res) => {
        if (res.data && res.data.status && !res.data.status) {
          message.destroy();
          message.error("File Download Error", 3);
          setDownloadProgress(0);
          setTimeout(() => {
            setDownloadProgressVisible(false);
            setTotalFileSize(0);
            setDownloadingFile("");
            setDownloadFinished(false);
          }, 5000);
        } else {
          console.log(res);
          setTimeout(() => {
            setDownloadFinished(true);
          }, 500);

          FileDownload(res.data, item.name);
          setTimeout(() => {
            setDownloadProgress(0);
            setDownloadProgressVisible(false);
            setTotalFileSize(0);
            setDownloadingFile("");
            setDownloadFinished(false);
          }, 5000);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  const shareFileHandler = () => {
    // console.log(currentFile);

    //code for testing if the newly adding user already exists in the access list
    let tempArr = [...currentFileAccessList];
    let test = tempArr.filter((item) => {
      return item.username === fileRecipient;
    });
    // console.log(test);
    if (fileRecipient.length > 0) {
      if (test.length == 0) {
        message.destroy();
        message.loading("Encrypting and granting the file access", 0);
        axios
          .post(value.url + "file/share-file", {
            //username: "karthik",
            email: value.email,
            token: value.token,
            path: currentFile.path,
            name: currentFile.name,
            folder: currentFile.folder,
            share_to: fileRecipient,
            withingx: true,
          })
          .then((res) => {
            if (res.data.status) {
              message.destroy();
              message.success("Access Granted Successfully", 3);
              setShareableGxLink(res.data.payload.link);
              setcurrentFileAccessList([
                ...currentFileAccessList,
                ...res.data.payload.userlist,
              ]);
              setFileRecipient("");
            }
          });
      } else {
        message.destroy();
        message.warning("Recipient Already Exists");
        setFileRecipient("");
      }
    } else {
      message.destroy();
      message.warning("Recipient cannot be empty", 3);
    }
  };

  const shareFileHandlerPublic = () => {
    message.destroy();
    message.loading("Encrypting and creating link", 0);
    axios
      .post(value.url + "file/share-file", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        path: currentFile.path,
        name: currentFile.name,
        folder: currentFile.folder,
        share_to: fileRecipient,
        withingx: false,
      })
      .then((res) => {
        if (res.data.status) {
          message.destroy();
          message.success("Public Link Generated Successfully");
          setShareablePublicLink(res.data.payload.link);
        }
      });
  };

  const revokeFileAccess = (item) => {
    // console.log(currentShareableLink);
    console.log(currentFile);
    let tempArr = [...currentFileAccessList];
    let newArr = tempArr.filter((obj) => {
      return obj.username !== item.username;
    });
    axios
      .post(value.url + "file/revoke-file-access", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        path: currentFile.path,
        name: currentFile.name,
        folder: currentFile.folder,
        revoke_from: item.username,
        withingx: true,
      })
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          message.destroy();
          message.success("Access Revoke Success");
          setcurrentFileAccessList([...newArr]);
        }
      });
  };

  const permanentDeleteFileHandler = (item) => {
    console.log(item);
    message.loading("Deleting File..", 0);
    axios
      .post(value.url + "file/permanent-delete", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: item.name,
        path: value.path + item.name,
      })
      .then((res) => {
        console.log(res.data.payload);
        let tempObj = [...userSharedFiles];
        console.log(tempObj);
        if (res.data.status) {
          tempObj.splice(
            tempObj.findIndex(
              (subitem) =>
                subitem.name === item.name &&
                subitem.path === item.path &&
                subitem.folder === item.folder
            ),
            1
          );
          console.log(tempObj);
          setUserSharedFiles([...tempObj]);
          message.destroy();
          message.success("File Deletion Success", 3);
        } else {
          message.destroy();
          message.error("File Deletion Error", 3);
        }
      });
  };

  return (
    <>
      {window.innerWidth > 600 ? (
        <Row style={{ minHeight: "100vh", position: "relative" }}>
          <Col
            //   className="shadow"
            //   height="100vh"
            xs={24}
            sm={4}
            style={{
              backgroundColor: "white",
              position: "fixed",
              left: 0,
              top: 0,
              height: "100vh",
            }}
          >
            <Sidebar />
          </Col>
          <Col
            xs={24}
            sm={20}
            offset={window.innerWidth > 600 ? 4 : 0}
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              style={{ padding: "30px", height: window.innerHeight }}
              className="scroll-style"
            >
              <div style={{ marginTop: "-10px" }}>
                <p
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    class="fa fa-trash"
                    style={{
                      fontSize: "28px",

                      marginLeft: "-2px",
                    }}
                  ></i>
                  &nbsp;&nbsp;Deleted Files
                </p>
                <Divider style={{ marginTop: "-10px" }} />
              </div>

              {userSharedFiles.length > 0 ? (
                <>
                  {userSharedFiles.map((item) => {
                    return (
                      <>
                        <Row gutter={16}>
                          <Col
                            span={18}
                            style={{
                              fontSize: "15px",
                              fontWeight: "600",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require(`../img/file-types/${item.file_type}.png`)}
                              width="25px"
                              alt=""
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{item.name}</span>
                          </Col>
                          <Col span={6}>
                            <Row gutter={16}>
                              {" "}
                              <Col
                                span={9}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "15.5px",
                                  fontWeight: "500",
                                  // justifyContent: "center"
                                }}
                              >
                                <i
                                  class="fa fa-database"
                                  style={{ color: "#757575" }}
                                ></i>
                                &nbsp;&nbsp;
                                {(item.file_size / 10 ** 6).toFixed(3) >
                                1000 ? (
                                  <>
                                    {" "}
                                    {(item.file_size / 10 ** 9).toFixed(3)}
                                    &nbsp;GB
                                  </>
                                ) : (item.file_size / 10 ** 6).toFixed(3) >
                                  0.1 ? (
                                  <>
                                    {(item.file_size / 10 ** 6).toFixed(3)}
                                    &nbsp;MB
                                  </>
                                ) : (
                                  <>
                                    {(item.file_size / 10 ** 3).toFixed(3)}
                                    &nbsp;KB
                                  </>
                                )}
                              </Col>
                              <Col
                                span={15}
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <Tooltip placement="top" title={"Download"}>
                                  <Button
                                    onClick={(e) => downloadClick(item)}
                                    size="small"
                                    style={{ borderStyle: "none" }}
                                  >
                                    <Icon
                                      type="cloud-download"
                                      style={{ fontSize: "20px" }}
                                    />
                                  </Button>
                                </Tooltip>
                                {/* <Tooltip
                                  placement="top"
                                  title={"Restore to original location"}
                                >
                                  <Button
                                    style={{ borderStyle: "none" }}
                                    size="small"
                                    onClick={(e) => {}}
                                  >
                                    <i
                                      class="fa fa-trash-restore-alt"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        color: "#81C784",
                                      }}
                                    ></i>
                                  </Button>
                                </Tooltip>
                                 */}
                                {/* <Tooltip placement="top" title={"Commit"}>
                                <Button
                                  size="small"
                                  style={{
                                    borderStyle: "none"
                                  }}
                                >
                                  <Icon
                                    type="github"
                                    style={{ fontSize: "18px" }}
                                  />
                                </Button>
                              </Tooltip> */}
                                <Tooltip
                                  placement="top"
                                  title={"Delete file permanently"}
                                >
                                  <Button
                                    size="small"
                                    style={{
                                      borderStyle: "none",
                                    }}
                                    onClick={(e) =>
                                      permanentDeleteFileHandler(item)
                                    }
                                  >
                                    <Icon
                                      type="delete"
                                      style={{
                                        fontSize: "18px",
                                        color: "#f44336",
                                        fontWeight: "bold",
                                      }}
                                    />
                                    {/* <Icon
                                    type="stop"
                                    style={{
                                      fontSize: "18px",
                                      color: "#f44336",
                                      fontWeight: "bold"
                                    }}
                                  /> */}
                                    {/* <i
                                      class="fa fa-ban"
                                      style={{
                                        fontSize: "18px",
                                        color: "#FF7043",
                                        fontWeight: "bold",
                                      }}
                                    ></i> */}
                                  </Button>
                                </Tooltip>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Divider dashed style={{ margin: "10px 0px" }} />
                      </>
                    );
                  })}
                </>
              ) : (
                <Result status="404" title="No Deleted File" />
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <NavMobile />
          <div
            style={{ padding: "30px", height: window.innerHeight }}
            className="scroll-style"
          >
            <div style={{ marginTop: "-10px" }}>
              <p
                style={{
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                <i
                  class="fa fa-trash"
                  style={{
                    fontSize: "28px",

                    marginLeft: "-2px",
                  }}
                ></i>
                &nbsp;&nbsp;Deleted Files
              </p>
              <Divider style={{ marginTop: "-10px" }} />
            </div>

            {userSharedFiles.length > 0 ? (
              <>
                {userSharedFiles.map((item) => {
                  return (
                    <>
                      <Row gutter={16}>
                        <Col
                          span={18}
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require(`../img/file-types/${item.file_type}.png`)}
                            width="25px"
                            alt=""
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>{item.name}</span>
                        </Col>
                        <Col span={6}>
                          <Row gutter={16}>
                            {" "}
                            <Col
                              span={9}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "15.5px",
                                fontWeight: "500",
                                // justifyContent: "center"
                              }}
                            >
                              <i
                                class="fa fa-database"
                                style={{ color: "#757575" }}
                              ></i>
                              &nbsp;&nbsp;
                              {(item.file_size / 10 ** 6).toFixed(3) > 1000 ? (
                                <>
                                  {" "}
                                  {(item.file_size / 10 ** 9).toFixed(3)}
                                  &nbsp;GB
                                </>
                              ) : (item.file_size / 10 ** 6).toFixed(3) >
                                0.1 ? (
                                <>
                                  {(item.file_size / 10 ** 6).toFixed(3)}
                                  &nbsp;MB
                                </>
                              ) : (
                                <>
                                  {(item.file_size / 10 ** 3).toFixed(3)}
                                  &nbsp;KB
                                </>
                              )}
                            </Col>
                            <Col
                              span={15}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              <Tooltip placement="top" title={"Download"}>
                                <Button
                                  onClick={(e) => downloadClick(item)}
                                  size="small"
                                  style={{ borderStyle: "none" }}
                                >
                                  <Icon
                                    type="cloud-download"
                                    style={{ fontSize: "20px" }}
                                  />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title={"Restore to original location"}
                              >
                                <Button
                                  style={{ borderStyle: "none" }}
                                  size="small"
                                  onClick={(e) => {}}
                                >
                                  <i
                                    class="fa fa-trash-restore-alt"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      color: "#81C784",
                                    }}
                                  ></i>
                                </Button>
                              </Tooltip>
                              {/* <Tooltip placement="top" title={"Commit"}>
                                <Button
                                  size="small"
                                  style={{
                                    borderStyle: "none"
                                  }}
                                >
                                  <Icon
                                    type="github"
                                    style={{ fontSize: "18px" }}
                                  />
                                </Button>
                              </Tooltip> */}
                              <Tooltip
                                placement="top"
                                title={"Delete file permanently"}
                              >
                                <Button
                                  size="small"
                                  style={{
                                    borderStyle: "none",
                                  }}
                                  onClick={(e) =>
                                    permanentDeleteFileHandler(item)
                                  }
                                >
                                  {/* <Icon type="delete" /> */}
                                  {/* <Icon
                                    type="stop"
                                    style={{
                                      fontSize: "18px",
                                      color: "#f44336",
                                      fontWeight: "bold"
                                    }}
                                  /> */}
                                  <i
                                    class="fa fa-ban"
                                    style={{
                                      fontSize: "18px",
                                      color: "#FF7043",
                                      fontWeight: "bold",
                                    }}
                                  ></i>
                                </Button>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider dashed style={{ marginTop: "10px" }} />
                    </>
                  );
                })}
              </>
            ) : (
              <Result status="404" title="No Deleted File" />
            )}
          </div>
        </>
      )}
      <Modal
        destroyOnClose
        footer={null}
        visible={downloadProgressVisible}
        // onOk={this.handleOk}
        onCancel={(e) => {
          setDownloadProgressVisible(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {zipDownload ? (
            <>
              {!downloadFinished ? (
                <>
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                  <span
                    style={{
                      marginTop: "20px",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Zipping & Downloading
                  </span>
                </>
              ) : (
                ""
              )}

              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Name: {downloadingFile}
              </span>
            </>
          ) : (
            <>
              {" "}
              <Progress type="circle" percent={downloadProgress} />
              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Name: {downloadingFile}
              </span>
              <span
                style={{
                  marginTop: "5px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Size: {totalFileSize.toFixed(3)}MB{" "}
                {/* <small>
              <i>approximately</i>
            </small> */}
              </span>
            </>
          )}

          {downloadFinished ? (
            <span
              style={{ marginTop: "20px", fontSize: "15px", fontWeight: "500" }}
            >
              Saving file... Check Downloads section in browser.
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal>

      {/* Sharefile Modal */}
      <Modal
        width={500}
        bodyStyle={{ padding: "30px" }}
        closable={false}
        // title="Basic Modal"
        footer={null}
        visible={visibleShareFile}
        onCancel={(e) => {
          setVisibleShareFile(false);
          setTimeout(() => {
            setCurrentFile({});
            setCurrentShareableLink("");
            setFileRecipient("");
            setcurrentFileAccessList([]);
            setShareablePublicLink("");
            setShareableGxLink("");
          }, 300);
        }}
      >
        <Tabs defaultActiveKey="2" style={{ textAlign: "center" }}>
          <TabPane
            tab={
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                <i class="fa fa-globe"></i>
                &nbsp;&nbsp;&nbsp;Public Link
              </span>
            }
            key="1"
          >
            <br />
            {shareablePublicLink.length > 10 ? (
              ""
            ) : (
              <>
                <Button
                  block
                  style={{ fontWeight: "bold" }}
                  onClick={shareFileHandlerPublic}
                >
                  Generate publicly available link
                </Button>
                <br />
                <br />
              </>
            )}

            {shareablePublicLink.length > 0 ? (
              <>
                <b>Shareable File Link</b>{" "}
                <Paragraph copyable={{ text: shareablePublicLink }}>
                  {shareablePublicLink.substring(0, 50)}...
                  {shareablePublicLink.substring(
                    shareablePublicLink.length - 30,
                    shareablePublicLink.length
                  )}
                </Paragraph>{" "}
              </>
            ) : (
              ""
            )}
          </TabPane>

          <TabPane
            tab={
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                <img
                  src={require("../img/gx_blue.svg")}
                  width="16px"
                  style={{ marginTop: "-3px" }}
                />
                &nbsp;&nbsp;&nbsp;Inside GX
              </span>
            }
            key="2"
          >
            <form onSubmit={shareFileHandler}>
              <Input
                type="text"
                size="large"
                placeholder="Type the recipient"
                value={fileRecipient}
                onChange={(e) => setFileRecipient(e.target.value)}
              />

              <Button
                block
                style={{ marginTop: "20px" }}
                onClick={shareFileHandler}
              >
                <b>
                  {currentFileAccessList.length > 0 ||
                  shareableGxLink.length > 0 ? (
                    <>
                      <i class="fa fa-user-plus"></i>&nbsp;&nbsp;Grant Access
                    </>
                  ) : (
                    <>
                      <Icon type="share-alt" />
                      &nbsp;&nbsp; Create Link & Grant Access
                    </>
                  )}
                </b>
              </Button>

              {currentFileAccessList.length > 0 ||
              shareableGxLink.length > 0 ? (
                <></>
              ) : (
                <div
                  style={{
                    fontWeight: "600",
                    fontStyle: "italic",
                    color: "#9E9E9E",
                    textAlign: "center",
                  }}
                >
                  <br />
                  <br />
                  Type atleast one username to generate a link
                </div>
              )}

              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "400px",
                    overflowWrap: "word-break",
                    textAlign: "center",
                  }}
                >
                  <br />
                  {shareableGxLink.length > 0 ? (
                    <>
                      <b>Shareable File Link</b>{" "}
                      <Paragraph copyable={{ text: shareableGxLink }}>
                        {shareableGxLink.substring(0, 50)}...
                        {shareableGxLink.substring(
                          shareableGxLink.length - 30,
                          shareableGxLink.length
                        )}
                      </Paragraph>{" "}
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        Access User List
                      </div>
                      {currentFileAccessList.length > 0 ? (
                        currentFileAccessList.map((item) => (
                          <div style={{ marginBottom: "5px" }}>
                            <Card
                              bodyStyle={{
                                margin: "2px 0px",
                                padding: "3px",
                                borderRadius: "3px",
                              }}
                            >
                              <Row gutter={16}>
                                <Col span={12}>
                                  <div
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {item.username}
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <Tooltip
                                    placement="right"
                                    title={"Revoke Access"}
                                  >
                                    <div>
                                      <i
                                        class="fa fa-user-minus"
                                        style={{
                                          fontSize: "17px",
                                          cursor: "pointer",
                                          color: "#FF7043",
                                          float: "right",
                                          marginTop: "3px",
                                          marginRight: "15px",
                                        }}
                                        onClick={(e) => revokeFileAccess(item)}
                                      ></i>
                                    </div>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Card>
                          </div>
                        ))
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default SharedFiles;
