import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  notification,
  Form,
  message,
  Icon,
  Modal,
  Drawer,
} from "antd";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../config";
import { FileContext } from "../Context";
import isEmail from "validator/lib/isEmail";
import Axios from "axios";

const Login = () => {
  const value = useContext(FileContext);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  const [rUsername, setRusername] = useState("");
  const [rEmail, setRemail] = useState("");
  const [rPassword, setRpassword] = useState("");
  const [rPassword1, setRpassword1] = useState("");
  const [visibleVerify, setVisibleVerify] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [visibleButtons, setVisibleButtons] = useState(false);
  const [visibleLogin, setVisibleLogin] = useState(false);
  const [visibleSignup, setVisibleSignup] = useState(false);
  const [visible2FA, setVisible2FA] = useState(false);
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  useEffect(() => {
    notification.open({
      message: "New Feature",
      description: (
        <b>
          Multi-File Upload is added.
          <br /> Please do check it out.
        </b>
      ),
      icon: <Icon type="star" theme="filled" style={{ color: "#FFD700" }} />,
      placement: "topLeft",
    });
  }, []);

  const handleLogin = () => {
    if (isEmail(userEmail) && password !== "") {
      Axios.post("https://gxauth.apimachine.com/gx/user/login", {
        email: userEmail,
        password: password,
      }).then((res) => {
        if (res.data.status) {
          message.success("Login Successfull");
          localStorage.setItem("access_token", res.data.accessToken);
          localStorage.setItem("user_account", userEmail);
          localStorage.setItem("token", res.data.idToken);
          window.location.reload();
        } else if (res.data.mfa) {
          setVisible2FA(true);
        }
        //  else {
        //   localStorage.removeItem("token");
        //   // console.log(err);
        //   message.destroy();
        //   message.error(res.data.message, 2);
        //   // if (err.message === "User is not confirmed.") {
        //   //   setVisibleVerify(true);
        //   // }
        // }
      });
    }
  };

  // const handleLogin = () => {
  //   if (isEmail(userEmail) && password !== "") {
  //     message.loading("Authenticating...");

  //     let authenticationData = {
  //       Username: userEmail,
  //       Password: password,
  //     };

  //     let authdet = new AuthenticationDetails(authenticationData);

  //     let userData = {
  //       Username: userEmail,
  //       Pool: userPool,
  //     };

  //     let user = new CognitoUser(userData);

  //     user.authenticateUser(authdet, {
  //       onSuccess: function (result) {
  //         //console.log(result);
  //         message.destroy();
  //         message.success("Login Successfull");
  //         let accessToken = result.accessToken.jwtToken;
  //         localStorage.setItem("access_token", accessToken);
  //         localStorage.setItem("user_account", userEmail);
  //         //   setTimeout(() => {
  //         //     value.setLogin(true);
  //         //   }, 1000);
  //         var idtoken = result.getIdToken().getJwtToken();
  //         //idtoken.client_id = client_id;
  //         localStorage.setItem("token", idtoken);
  //         window.location.reload();
  //       },

  //       onFailure: function (err) {

  //       },
  //     });
  //   } else {
  //     message.destroy();
  //     message.error("Please enter Email & password");
  //   }
  // };

  const handleSignup = () => {
    if (
      rUsername !== "" &&
      rEmail !== "" &&
      rPassword !== "" &&
      rPassword1 !== ""
    ) {
      if (isEmail(rEmail)) {
        if (rPassword === rPassword1) {
          message.loading("Signing you up...");
          Axios.post(`https://gxauth.apimachine.com/gx/user/signup`, {
            username: rUsername,
            email: rEmail,
            password: rPassword,
            ref_affiliate: "1",
            account_type: "Personal",
            signedup_app: "Brain",
          }).then((res) => {
            if (res.data.status === true) {
              // message.destroy();
              // message.success("Successfully Registered, Verify to continue.");
              setVisibleVerify(true);
            } else {
              message.destroy();
              message.error(res.data.message);
            }
          });
        }
      } else {
        message.error("invalid email");
      }
    } else {
      message.error("fill all fields");
    }
  };

  const handle2FA = () => {
    Axios.post(`https://gxauth.apimachine.com/gx/user/login`, {
      email: userEmail,
      password: password,
      totp_code: verificationCode,
    }).then((res) => {
      if (res.data.status) {
        message.success("Login Successfull");
        localStorage.setItem("access_token", res.data.accessToken);
        localStorage.setItem("user_account", userEmail);
        localStorage.setItem("token", res.data.idToken);
        window.location.reload();
      }
    });
  };

  const handleVerify = () => {
    if (verificationCode !== "" && isEmail(rEmail)) {
      Axios.post(`https://gxauth.apimachine.com/gx/user/confirm`, {
        email: rEmail,
        code: verificationCode,
      }).then((res) => {
        if (res.data.status) {
          setVisibleVerify(false);
          message.destroy();
          message.success(
            "Your Account gas been verified. Please login to continue."
          );
        }
      });
    }
  };

  const conditionalForm = () => {
    if (!visibleLogin && !visibleSignup) {
      return (
        <>
          <Button
            onClick={(e) => setVisibleLogin(true)}
            size="large"
            ghost
            style={{ width: "200px", marginBottom: "20px" }}
          >
            E N T E R
          </Button>
          <Button
            size="large"
            style={{ width: "200px" }}
            onClick={(e) => setVisibleSignup(true)}
          >
            C O N N E C T
          </Button>
        </>
      );
    } else if (visibleLogin === true) {
      return (
        <>
          <Input
            style={{ marginTop: "20px", width: "300px" }}
            valur={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            type="text"
            placeholder="E M A I L"
            className="underline-input"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Input
            valur={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="P A S S W O R D"
            className="underline-input"
          />
          <br />
          <Button ghost onClick={handleLogin} style={{ marginTop: "30px" }}>
            E N T E R
          </Button>
        </>
      );
    } else if (visibleSignup === true) {
      return (
        <>
          <Input
            value={rUsername}
            onChange={(e) => setRusername(e.target.value)}
            type="text"
            placeholder="U S E R N A M E"
            className="underline-input"
            style={{ marginBottom: "30px", width: "300px" }}
          />

          <Input
            value={rEmail}
            onChange={(e) => setRemail(e.target.value)}
            type="text"
            placeholder="E M A I L"
            className="underline-input"
            style={{ marginBottom: "30px" }}
          />

          <Input
            value={rPassword}
            onChange={(e) => setRpassword(e.target.value)}
            type="password"
            placeholder="P A S S W O R D"
            className="underline-input"
            style={{ marginBottom: rPassword === "" ? "10px" : "" }}
          />
          <div style={{ marginBottom: "20px", textAlign: "right" }}>
            {rPassword !== "" ? (
              strongRegex.test(rPassword) ? (
                <span style={{ color: "green" }}>✔ Strong Password</span>
              ) : (
                <span style={{ color: "red" }}>
                  ❌ Password is not strong, should contain a Capital Letter, a
                  special charecter &
                </span>
              )
            ) : (
              ""
            )}
          </div>

          <Input
            value={rPassword1}
            onChange={(e) => setRpassword1(e.target.value)}
            type="password"
            placeholder="V E R I F Y  P A S S W O R D"
            className="underline-input"
          />

          <Button ghost onClick={handleSignup} style={{ marginTop: "40px" }}>
            C O N N E C T
          </Button>
        </>
      );
    }
  };

  const showTextorImage = () => {
    if (visibleLogin === true && visibleSignup === false) {
      return <h1 style={{ color: "white" }}>Login</h1>;
    } else if (visibleLogin === false && visibleSignup === true) {
      return <h1 style={{ color: "white" }}>Signup</h1>;
    } else {
      return (
        <img src={require("../img/logo_white.png")} alt="" width="150px" />
      );
    }
  };

  return (
    <>
      {window.innerWidth > 600 ? (
        <div
          className="side-bg"
          style={{
            height: "100vh",
            padding: "60px 80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={require("../img/logo_white.png")} width="45px" />{" "}
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "15px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              <p>STORAGE</p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <p>STREAM</p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <p>NETWORK</p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <p>TOKEN</p>
            </div>
          </div>
          <div
            style={{
              color: "white",
              marginBottom: "8vh",
            }}
          >
            <p style={{ fontSize: "80px", margin: "0px" }}>
              <b>Brain</b>.Stream
            </p>
            <p style={{ fontSize: "44px", margin: "0px", marginTop: "-20px" }}>
              Building The Data Carta
            </p>
            <p style={{ paddingTop: "20px", fontSize: "18px" }}>
              Brain.Stream is a data provenance platform that allows users to
              <br />
              create anonymous virtual environments for store, sharing and
              <br />
              mortising their data.{" "}
            </p>
            <Button
              size="large"
              style={{ color: "#152A77", fontWeight: "bold" }}
              onClick={(e) => setVisibleButtons(true)}
            >
              ACCESS BRAIN
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              height: "10vh",
              backgroundColor: "#152A77",
              padding: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={require("../img/logo_white.png")} alt="" width="40px" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflowX: "hidden",
            }}
          >
            <div style={{ padding: "30px 0px" }}>
              <p
                style={{
                  color: "#152A77",
                  fontSize: "30px",
                  fontWeight: "bold",
                  margin: "0px",
                  paddingLeft: "27px",
                }}
              >
                Brain.Stream
              </p>
              <p
                style={{
                  color: "#787C7E",
                  fontSize: "20px",
                  margin: "0px",
                  paddingLeft: "27px",
                }}
              >
                The Data Carta
              </p>
              <br />
              <div className="mobile-button">Storage</div>
              <div className="mobile-button">Stream</div>
              <div className="mobile-button">Network</div>
              <div className="mobile-button">Token</div>
            </div>
            <div
              className="from-right"
              style={{
                position: "relative",
                right: "20vw",
              }}
            >
              <img src={require("../img/robot.png")} alt="" />
            </div>
          </div>
          <div
            style={{
              height: "20vh",
              position: "relative",
              backgroundColor: "#152A77",
              // height: "18vh",
              // padding: "20px 0px",
              borderRadius: "50px 0 0 0",
              zIndex: 1,
              // marginTop: "-50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="bottom-button"
              onClick={(e) => setVisibleButtons(true)}
            >
              Access Brain
            </div>
          </div>
        </div>
      )}

      <Drawer
        // title="Basic Drawer"
        placement="top"
        height="100vh"
        closable={true}
        onClose={(e) => {
          setVisibleButtons(false);
          setVisibleLogin(false);
          setVisibleSignup(false);
        }}
        visible={visibleButtons}
        bodyStyle={{ backgroundColor: "#152A77" }}
      >
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {showTextorImage()}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              {conditionalForm()}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",

              color: "white",
            }}
          >
            <div> P O W E R E D &nbsp;B Y</div>&nbsp;
            <img src={require("../img/gx.png")} alt="" width="20px" />{" "}
          </div>
        </div>
      </Drawer>

      {/* <Row>
        <Col span={14} className="side-bg" style={{ height: "100vh" }}></Col>
        <Col
          span={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "#005166",
              display: "flex",
              padding: "25px",
            }}
          >
            {/* <TextField id="standard-basic" label="U S E R N A M E" /> 
            <Input
              valur={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="E M A I L"
              className="underline-input"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Input
              valur={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="P A S S W O R D"
              className="underline-input"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
            <Icon
              type="login"
              style={{
                cursor: "pointer",
                fontSize: "25px",
                fontWeight: "bold",
                color: "white",
              }}
              onClick={handleLogin}
            />
          </div>
          <div style={{ padding: "35px" }}>
            <h1
              style={{
                color: "#306AAE",
                fontWeight: "bold",
                letterSpacing: "6px",
                paddingLeft: "8px",
              }}
            >
              GET STARTED
            </h1>
            <br />
            <Input
              value={rUsername}
              onChange={(e) => setRusername(e.target.value)}
              type="text"
              placeholder="U S E R N A M E"
              className="underline-input1"
              style={{ marginBottom: "30px" }}
            />

            <Input
              value={rEmail}
              onChange={(e) => setRemail(e.target.value)}
              type="text"
              placeholder="E M A I L"
              className="underline-input1"
              style={{ marginBottom: "30px" }}
            />

            <Input
              value={rPassword}
              onChange={(e) => setRpassword(e.target.value)}
              type="password"
              placeholder="P A S S W O R D"
              className="underline-input1"
              style={{ marginBottom: rPassword === "" ? "10px" : "" }}
            />
            <div style={{ marginBottom: "20px", textAlign: "right" }}>
              {rPassword !== "" ? (
                strongRegex.test(rPassword) ? (
                  <span style={{ color: "green" }}>✔ Strong Password</span>
                ) : (
                  <span style={{ color: "red" }}>
                    ❌ Password is not strong, should contain a Capital Letter,
                    a special charecter &
                  </span>
                )
              ) : (
                ""
              )}
            </div>

            <Input
              value={rPassword1}
              onChange={(e) => setRpassword1(e.target.value)}
              type="password"
              placeholder="V E R I F Y  P A S S W O R D"
              className="underline-input1"
            />
          </div>
          <div
            onClick={handleSignup}
            style={{
              backgroundColor: "#005166",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              cursor: "pointer",
            }}
          >
            <h1
              style={{
                margin: "0px",
                color: "white",
                fontWeight: "bold",
                fontSize: "19px",
              }}
            >
              C O N N E C T
            </h1>
          </div>
        </Col>
      </Row> */}

      {/* Verify with OTP Modal */}

      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={visibleVerify}
        onCancel={(e) => setVisibleVerify(false)}
        footer={null}
        // bodyStyle={{ padding: "0px" }}
      >
        <h2>Signup Verification</h2>
        <p>
          You have received a verification code in your registered Email. Please
          type both Email and the code and click on verify to proceed
        </p>
        <Input
          placeholder="Enter Registered Email"
          style={{ marginBottom: "10px" }}
          type="text"
          value={rEmail}
          onChange={(e) => setRemail(e.target.value)}
        />
        <Input
          placeholder="Enter Verification Code"
          style={{ marginBottom: "10px" }}
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button block type="primary" size="large" onClick={handleVerify}>
          V E R I F Y
        </Button>
      </Modal>
      {/* // 2FA verify */}
      <Modal
        centered
        closable={false}
        title={
          <>
            <Icon
              type="safety-certificate"
              style={{ fontWeight: "bold", color: "green" }}
            />
            &nbsp;&nbsp;2FA Verification
          </>
        }
        width={290}
        visible={visible2FA}
        onCancel={(e) => setVisible2FA(false)}
        footer={null}
        // bodyStyle={{ textAlign: "center" }}
      >
        {/* <h2>2FA Verification</h2> */}
        <p style={{ marginTop: "-10px" }}>
          Enter Code from google authenticator
        </p>

        <Input
          size="large"
          placeholder="Enter 2FA Code"
          style={{ marginBottom: "10px" }}
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button block type="primary" size="large" onClick={handle2FA}>
          V E R I F Y
        </Button>
      </Modal>
    </>
  );
};

export default Login;
