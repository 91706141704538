import React, { useState, useEffect, useContext } from "react";
import Sidebar from "./Sidebar";
import {
  Row,
  Col,
  Input,
  Icon,
  Card,
  Divider,
  Alert,
  Menu,
  Dropdown,
  Modal,
  Button,
  message,
  Popover,
  Tooltip,
  Progress,
  Empty,
  Typography,
  Tabs,
  Result,
  Avatar,
} from "antd";
import { FileContext } from "../Context";
import axios from "axios";
import blurbg from "../img/gradientbg.jpg";
import S3 from "aws-s3";

const { Paragraph } = Typography;
const { TextArea } = Input;
const { Meta } = Card;

const CreatePostVideo = () => {
  const {
    fileFolderData,
    pulseTradingFloorApiUrl,
    profiledVideoUrl,
  } = useContext(FileContext);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);

  const [query, setQuery] = useState("");
  const [step1, setStep1] = useState(false);
  //   const [step2, setStep2] = useState(false);
  const [courseArr, setCourseArr] = useState([]);
  const [finalArr, setFinalArr] = useState([]);

  const [visibleEditChapter, setVisibleEditChapter] = useState(false);
  //   const chapterTitleRef = useRef("");
  //   const chapterDescRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [thumbnailCloudfronturl, setThumbnailCloudfronturl] = useState("");
  const [selectedChapter, setSelectedChapter] = useState({});
  const [visibleMetadataModal, setVisibleMetadataModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [profiledVideos, setProfiledVideos] = useState([]);

  const [allVideos, setAllVideos] = useState([]);
  const [editing, setEditing] = useState(false);

  const [refetchFilteredVideos, setRefetchFilteredVideos] = useState(false);
  const [videoPublic, setVideoPublic] = useState(false);

  const ThumbnailConfig = {
    bucketName: "videoprofiling",
    dirName: "drivevideothumbnails",
    region: "us-east-2",
    accessKeyId: "AKIAQD4QYXWFVPUPIJ76",
    secretAccessKey: "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",
  };

  //   useEffect(() => {
  //     let tempArr = [];
  //     allVideos.map(item => {
  //       // let index = profiledVideos.findIndex(o => o.video_id === item.id);
  //       // const newArr = courseArr.filter((_, i) => i !== index);
  //       profiledVideos.map(item1 => {
  //         if (item1.video_id !== item.id) {
  //           tempArr.push(item);
  //         }
  //       });
  //     });
  //     setAllVideos(tempArr);
  //   }, [refetchFilteredVideos]);

  useEffect(() => {
    axios
      .get(profiledVideoUrl + `get_file_list`, {
        headers: {
          email: localStorage.getItem("user_account"),
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAllVideos(res.data.payload);
        getMyProfiledVideos();
      });
  }, []);

  const addtoVideoPost = (item) => {
    let obj = profiledVideos.find((o) => o.video_id === item.id);

    if (obj) {
      message.info("You have already added metadata for this video");
      setVisibleMetadataModal(false);
    } else {
      message.destroy();
      setSelectedVideo(item);
      setVisibleMetadataModal(true);
    }
  };

  //   useEffect(() => {
  //     let temparr = [];
  //     if (profiledVideos.length > 0 && allVideos.length > 0) {
  //       profiledVideos.map(item => {
  //         allVideos.map(item1 => {
  //           if (item1.id !== item.video_id) {
  //             temparr.push(item1);
  //           }
  //         });
  //       });
  //       setAllVideos(temparr);
  //     }
  //   }, [profiledVideos]);

  var filteredFiles = allVideos.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.path + item.name).toLowerCase().indexOf(lowquery) >= 0 &&
      !item.folder
    );
  });

  useEffect(() => {
    filteredFiles = allVideos.filter((item) => {
      const lowquery = query.toLowerCase();
      return (
        (item.path + item.name).toLowerCase().indexOf(lowquery) >= 0 &&
        !item.folder
      );
    });
  }, [query]);

  const onThumbnailChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setThumbnail(e.target.result);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      uploadThumbnail(event.target.files[0]);
    }
  };

  const uploadThumbnail = async (selectedImg) => {
    setLoading(true);
    const S3Client = new S3(ThumbnailConfig);
    // let uploaded = await S3Client.uploadFile(selectedImg);
    let uploaded_data;
    try {
      //   message.loading("uploading...");
      uploaded_data = await S3Client.uploadFile(
        selectedImg,
        selectedImg.name.replace(/\s/g, "")
      );
      message.success("File Upload Success");
      let url =
        "https://djoplec97gdag.cloudfront.net" +
        uploaded_data.location.substring(55, uploaded_data.location.length);
      setThumbnailCloudfronturl(url);
      setLoading(false);
      message.destroy();
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  const getMyProfiledVideos = () => {
    setRefetchFilteredVideos(true);
    axios
      .post(
        profiledVideoUrl + `get_my_profiled_video_list`,
        {},
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setRefetchFilteredVideos(false);
        if (res.data.status) {
          console.log("profiled", res.data.payload);
          setProfiledVideos(res.data.payload);
        } else {
          message.error("Fetching Profiled Video Failed", 3);
        }
      });
  };

  const addMetadata = () => {
    axios
      .post(
        profiledVideoUrl + `new_profiled_video`,
        {
          title: title,
          subtitle: subtitle,
          description: videoDescription,
          thumbnail: thumbnailCloudfronturl,
          path: selectedVideo.path,
          name: selectedVideo.name,
          folder: selectedVideo.folder,
          file_type: selectedVideo.file_type,
          file_size: selectedVideo.file_size,
          run_time: selectedVideo.run_time,
          video_id: selectedVideo.id,
          email: selectedVideo.email,
          user_drive_id: selectedVideo.user_drive_id,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          message.success("Video Profiling Success", 3);
          setVisibleMetadataModal(false);
          setTitle("");
          setSubtitle("");
          setVideoDescription("");
          setThumbnailCloudfronturl("");
          setThumbnail("");
          getMyProfiledVideos();
        } else {
          message.error("Video Profiling Failed", 3);
        }
      });
  };

  const updateMetadata = () => {
    axios
      .post(
        profiledVideoUrl + `update_profiled_video_data`,
        {
          title: title ? title : null,
          subtitle: subtitle ? subtitle : null,
          description: videoDescription ? videoDescription : null,
          thumbnail: thumbnailCloudfronturl ? thumbnailCloudfronturl : null,
          video_id: selectedVideo.video_id,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          message.success("Video Profiling Success", 3);
          setVisibleMetadataModal(false);
          setTitle("");
          setSubtitle("");
          setVideoDescription("");
          setThumbnailCloudfronturl("");
          setThumbnail("");
          getMyProfiledVideos();
        } else {
          message.error("Video Profiling Failed", 3);
        }
      });
  };

  const makeVideoPublic = (item) => {
    axios
      .post(
        profiledVideoUrl + `update_profiled_video_data`,
        {
          title: item.title,
          subtitle: item.subtitle,
          description: item.description,
          thumbnail: item.thumbnail,
          video_id: item.video_id,
          public: true,
        },
        {
          headers: {
            email: localStorage.getItem("user_account"),
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          getMyProfiledVideos();
        } else {
          message.error("Video Profiling Failed", 3);
        }
      });
  };

  return (
    <>
      <Row style={{ minHeight: "100vh", position: "relative" }}>
        <Col
          className="shadow"
          //   height="100vh"
          xs={24}
          sm={4}
          style={{
            backgroundColor: "white",
            zIndex: "1",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <Sidebar />
        </Col>
        <Col
          xs={24}
          sm={20}
          offset={window.innerWidth > 600 ? 4 : 0}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <Row style={{ overflowY: "hidden" }}>
            <Col
              //   className="shadow"
              span={10}
              style={{
                // borderRight: "1px dashed lightgray",
                height: "100vh",
              }}
            >
              <div style={{ padding: "20px" }}>
                <Input
                  size="large"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="search with video name or folder name..."
                  suffix={<Icon type="search" />}
                />
              </div>
              <Divider style={{ marginTop: "4px", marginBottom: "0px" }} />
              <div
                style={{
                  padding: "0px 20px",
                  overflowY: "scroll",
                  height: "90vh",
                }}
              >
                {filteredFiles.length > 0 ? (
                  filteredFiles.map((item, index) => {
                    if (item.file_type === "video") {
                      return (
                        <>
                          <Card
                            hoverable
                            title={
                              <>
                                <img
                                  //   style={{ marginTop: "-5px" }}
                                  src={require(`../img/file-types/${item.file_type}.png`)}
                                  alt=""
                                  width="20px"
                                />
                                &nbsp;&nbsp;{item.name}
                              </>
                            }
                            // extra={showIcon(item)}
                            onClick={(e) => addtoVideoPost(item)}
                            bodyStyle={{
                              padding: "10px",
                              backgroundColor: "#F5F5F5",
                            }}
                            //   headStyle={{ padding: "5px 8px" }}
                            style={{
                              margin: "20px 0px",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <Meta
                              description={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    type="home"
                                    style={{ fontSize: "12px" }}
                                  />
                                  &nbsp;&nbsp;{item.path}
                                </div>
                              }
                            />
                          </Card>
                        </>
                      );
                    }
                  })
                ) : (
                  <div style={{ padding: "30px" }}>
                    <Icon type="loading" style={{ fontSize: "20px" }} />
                  </div>
                )}
              </div>
            </Col>
            <Col
              span={14}
              style={{
                // padding: "10px",
                backgroundColor: "#F5F5F5",
                //   backgroundImage: `URL(${blurbg})`,
                //   backgroundSize: "cover",
                // opacity: "0.5",
                // borderRadius: "0px 20px 20px 0px",
                color: "white",
                height: "100vh",
              }}
            >
              {/* <h2>{courseTitle}</h2>
                  <p>{courseDesc}</p>
                  <Divider style={{ margin: "5px 0px" }} /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    color: "black",
                    fontSize: "27px",
                    fontWeight: "bold",
                  }}
                >
                  Profiled Videos
                </div>
                {/* <Input
                    size="large"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder="search video posts to edit..."
                    suffix={<Icon type="search" />}
                  /> */}
              </div>
              <Divider style={{ margin: "4px 0px" }} />
              <div style={{ overflowY: "scroll", height: "90vh" }}>
                {profiledVideos.length > 0
                  ? profiledVideos.map((item) => {
                      return (
                        <>
                          <div style={{ display: "flex", padding: "10px" }}>
                            <div>
                              <img
                                src={item.thumbnail}
                                alt=""
                                style={{ width: "250px" }}
                              />
                            </div>
                            <div style={{ padding: "0px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h6 style={{ display: "flex" }}>
                                  {item.title}&nbsp;{" "}
                                </h6>

                                <Icon
                                  onClick={(e) => {
                                    setVisibleMetadataModal(true);
                                    setEditing(true);
                                    setSelectedVideo(item);
                                  }}
                                  type="edit"
                                  style={{ color: "blue", cursor: "pointer" }}
                                />
                              </div>
                              <small
                                style={{
                                  color: "darkgray",
                                  fontWeight: "600",
                                }}
                              >
                                {item.subtitle}
                              </small>
                              <br />
                              <p
                                style={{
                                  color: "darkgray",
                                  marginTop: "10px",
                                }}
                              >
                                {item.description.substring(0, 200)}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  {item.public === false ? (
                                    <Button
                                      onClick={(e) => {
                                        makeVideoPublic(item);
                                      }}
                                    >
                                      Make Public
                                    </Button>
                                  ) : (
                                    <Paragraph
                                      copyable={{
                                        text: `https://videoshare.pulse.stream/?videoid=${item.video_id}`,
                                      }}
                                    />
                                  )}
                                </div>
                                &nbsp;
                                {item.public === true ? (
                                  <div
                                    style={{
                                      color: "gray",
                                      marginTop: "-9px",
                                    }}
                                  >
                                    Copy Public URL
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <Divider style={{ margin: "0px" }} />
                        </>
                      );
                    })
                  : ""}
              </div>
              <div
                className="bottom-info"
                style={{ marginBottom: "50px", textAlign: "center" }}
              ></div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Video MetaData Modal */}

      <Modal
        centered
        title={editing ? "Update Metadata to Video" : "Add Metadata of Video"}
        visible={visibleMetadataModal}
        // onOk={!loading ? addMetadata : ""}
        onCancel={(e) => setVisibleMetadataModal(false)}
        footer={null}
        bodyStyle={{ padding: "20px" }}
      >
        <Input
          placeholder="Enter Video Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Input
          style={{ marginTop: "10px" }}
          placeholder="Enter Video Subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />
        <TextArea
          value={videoDescription}
          onChange={(e) => setVideoDescription(e.target.value)}
          rows={4}
          placeholder="Enter Video Description"
          style={{ marginBottom: "10px", marginTop: "10px" }}
        />
        <div
          style={{
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: "cover",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: thumbnail ? "none" : "dashed",
            borderWidth: "1px",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <label for="image">
            <input
              accept="image/*"
              // value={coverImage}
              // onChange={e =>
              //   setCoverImage(URL.createObjectURL(e.target.files[0]))
              // }
              onChange={onThumbnailChange}
              type="file"
              name="image"
              id="image"
              style={{ display: "none" }}
            />
            <Icon
              type="camera"
              style={{
                fontSize: "40px",
                display: thumbnail ? "none" : "block",
                color: "lightgray",
              }}
            />

            {/* <Avatar
                    style={{
                      backgroundColor: "white",
                      zIndex: "0",
                      color: "black",
                      margin: "20px",
                      opacity: 1,
                      cursor: "pointer",
                      marginTop: "-30px"
                    }}
                    icon="camera"
                    size={80}
                  /> */}
          </label>
        </div>
        <Button
          onClick={editing ? updateMetadata : addMetadata}
          loading={loading}
          type="primary"
        >
          Submit
        </Button>
      </Modal>
    </>
  );
};

export default CreatePostVideo;
