import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Form,
  Alert,
  Icon,
  Input,
  Button,
  Card,
  Badge,
  Result,
  Avatar,
  Modal,
  message,
  Typography,
  Tooltip,
} from "antd";

import S3 from "aws-s3";

import { Link } from "react-router-dom";
import { FileContext } from "../Context";
import Sidebar from "./Sidebar";
import NavMobile from "./NavMobile";
import axios from "axios";
import ReactPlayer from "react-player";

const { TextArea } = Input;

const { Meta } = Card;
const { Paragraph } = Typography;

const ManageCourse = () => {
  const { fileFolderData, pulseTradingFloorApiUrl } = useContext(FileContext);

  const [manageFlag, setmanageFlag] = useState(false);
  const [selectedCourse, setselectedCourse] = useState("");
  const [existingCourses, setexistingCourses] = useState([]);
  const [editFlag, seteditFlag] = useState(false);
  const [selectedCard, setselectedCard] = useState({});
  const [updatedTitle, setupdatedTitle] = useState("");
  const [updatedDescription, setupdatedDescription] = useState("");
  const [addCourseModalVisibility, setAddCourseModalVisibility] = useState(
    false
  );
  const [chapterSelectedFlag, setchapterSelectedFlag] = useState(false);
  const [selectedChapter, setselectedChapter] = useState({});

  const [query, setQuery] = useState("");
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterDescription, setChapterDescription] = useState("");
  const [chapterSubtitle, setChapterSubtitle] = useState("");
  const [courseCover, setCourseCover] = useState(null);

  const [courseCoverS3url, setCourseCoverS3url] = useState("");
  const [selectedCoverImage, setselectedCoverImage] = useState(null);

  const CoverConfig = {
    bucketName: "gxsocialprofilecover",
    dirName: "coursecover",
    region: "us-east-2",
    accessKeyId: "AKIA4HKGLPLP7H73PB57",
    secretAccessKey: "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",
  };

  let filteredFiles = fileFolderData.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.path + item.name).toLowerCase().indexOf(lowquery) >= 0 &&
      !item.folder
    );
  });

  const onCoverImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCourseCover(e.target.result);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      setselectedCoverImage(event.target.files[0]);
      // uploadCourseCover(event.target.files[0]);
    }
  };

  const addChapter = async () => {
    const S3Client = new S3(CoverConfig);
    // let uploaded = await S3Client.uploadFile(selectedImg);
    let uploaded_data;
    try {
      message.destroy();
      message.loading("Adding Chapter", 0);
      // message.loading("uploading...");
      uploaded_data = await S3Client.uploadFile(
        selectedCoverImage,
        selectedCoverImage.name
      );

      let url =
        "https://djoplec97gdag.cloudfront.net" +
        uploaded_data.location.substring(55, uploaded_data.location.length);

      console.log(selectedCourse);
      let chapterObject = { ...selectedChapter };
      chapterObject["title"] = chapterTitle;
      chapterObject["subtitle"] = chapterSubtitle;
      chapterObject["desctription"] = chapterDescription;

      chapterObject["course_id"] = selectedCourse.id;
      chapterObject["creator_pulse_id"] = selectedCourse.creator_pulse_id;
      chapterObject["origination_source"] = selectedCourse.origination_source;
      chapterObject["post_id"] = selectedCourse.post_id;
      chapterObject["post_tier_id"] = selectedCourse.post_tier_id;
      chapterObject["cover_url"] = url;
      axios.post(pulseTradingFloorApiUrl + "add_chapter");
      console.log(chapterObject);
      let resp = await axios.post(
        pulseTradingFloorApiUrl + "add_chapter",
        {
          ...chapterObject,
        },
        {
          headers: {
            token: localStorage.getItem("token"),
            email: localStorage.getItem("user_account"),
          },
        }
      );

      if (resp.data.status) {
        message.destroy();
        message.success("Adding Chapter Success", 3);
        window.location.reload();
      } else {
        message.destroy();
        message.error("Adding Chapter Failed", 3);
      }
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let email = localStorage.getItem("user_account");
    axios
      .post(
        pulseTradingFloorApiUrl + "get_courses",
        {},

        {
          headers: {
            token,
            email,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setexistingCourses(res.data.payload);
        } else {
          message.error("Error in fetching your courses");
        }
      });
  }, []);

  return (
    <>
      <Row style={{ minHeight: "100vh", position: "relative" }}>
        {window.innerWidth > 600 ? (
          <Col
            //   className="shadow"
            //   height="100vh"
            xs={24}
            sm={4}
            style={{
              backgroundColor: "white",
              position: "fixed",
              left: 0,
              top: 0,
              height: "100vh",
            }}
          >
            <Sidebar />
          </Col>
        ) : (
          <NavMobile />
        )}
        <Col
          xs={24}
          sm={20}
          offset={window.innerWidth > 600 ? 4 : 0}
          style={{
            backgroundColor: "#FFFFFF",
            height: window.innerHeight,
            padding: "30px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <img
                src={require("../img/course.svg")}
                style={{
                  width: "80px",
                }}
              ></img>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "30px",
                  marginLeft: "20px",
                }}
              >
                Existing Courses
              </div>
            </div>
            {manageFlag ? (
              <div style={{ float: "left" }}>
                <Icon
                  type="left-circle"
                  theme="filled"
                  onClick={(e) => {
                    setmanageFlag(false);
                    setselectedCourse("");
                    seteditFlag(false);
                    setselectedCard({});
                    setupdatedTitle("");
                    setupdatedDescription("");
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <Divider dashed style={{ margin: "0px 0px" }} />

          {manageFlag ? (
            <>
              <Row style={{ display: "flex", flexWrap: "wrap" }}>
                {selectedCourse.chapters.map((item, index) => {
                  return (
                    <Col span={12}>
                      <Card
                        bodyStyle={{ padding: "14px", paddingBottom: "6px" }}
                        className="shadow-sm"
                        style={{
                          borderRadius: "8px",

                          margin: "10px",
                        }}
                        actions={[
                          <>
                            <Tooltip title="Viewer Count" placement="bottom">
                              <img
                                src={require("../img/viewers1.svg")}
                                style={{ marginTop: "-8px", width: "25px" }}
                              />{" "}
                              &nbsp;&nbsp;
                              <span
                                style={{
                                  marginTop: "90px",
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                              >
                                {item.view_count}
                              </span>
                            </Tooltip>
                          </>,
                          editFlag && item.id === selectedCard.id ? (
                            <Tooltip title="Approve Changes">
                              <Icon
                                type="check"
                                style={{
                                  fontWeight: "bold",
                                  color: "green",
                                  marginTop: "1px",
                                }}
                                onClick={(e) => {
                                  console.log(item.id);

                                  let title_to_be_updated =
                                    updatedTitle.length > 0
                                      ? updatedTitle
                                      : item.title;
                                  let desc_to_be_updated =
                                    updatedDescription.length > 0
                                      ? updatedDescription
                                      : item.description;
                                  axios
                                    .post(
                                      pulseTradingFloorApiUrl +
                                        "update_chapter",

                                      {
                                        title: title_to_be_updated,
                                        description: desc_to_be_updated,
                                        id: item.id,
                                      },
                                      {
                                        headers: {
                                          token: localStorage.getItem("token"),
                                          email: localStorage.getItem(
                                            "user_account"
                                          ),
                                        },
                                      }
                                    )
                                    .then(async (res) => {
                                      if (res.data.status) {
                                        message.success("Updation Success", 3);

                                        await Promise.all(
                                          selectedCourse.chapters.map(
                                            (chap) => {
                                              if (chap.id === selectedCard.id) {
                                                chap.title = title_to_be_updated;
                                                chap.description = desc_to_be_updated;
                                              }
                                            }
                                          )
                                        );
                                        seteditFlag(false);
                                        setselectedCard({});
                                        setupdatedTitle("");
                                        setupdatedDescription("");
                                      } else {
                                        message.error("Updation Failed");
                                      }
                                    });
                                  console.log(
                                    updatedDescription.length > 0
                                      ? updatedDescription
                                      : item.description
                                  );
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Icon
                              type="edit"
                              key="edit"
                              style={{ fontSize: "18px", marginTop: "1px" }}
                              onClick={(e) => {
                                seteditFlag(true);
                                setselectedCard(item);
                              }}
                            />
                          ),
                          editFlag && item.id === selectedCard.id ? (
                            <Tooltip title="Discard Changes">
                              <Icon
                                type="close"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  marginTop: "1px",
                                }}
                                onClick={(e) => {
                                  seteditFlag(false);
                                  setselectedCard({});
                                  setupdatedTitle("");
                                  setupdatedDescription("");
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          ),
                        ]}
                      >
                        <Row
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            display: "flex",
                          }}
                        >
                          <div>{index + 1}</div>
                          {}&nbsp;&nbsp;
                          <div>
                            {editFlag && item.id === selectedCard.id ? (
                              <Input
                                size="small"
                                type="text"
                                value={updatedTitle}
                                placeholder={item.title}
                                onChange={(e) =>
                                  setupdatedTitle(e.target.value)
                                }
                              />
                            ) : (
                              item.title
                            )}
                          </div>
                        </Row>
                        <Row style={{ fontSize: "15px" }}>
                          {editFlag && item.id === selectedCard.id ? (
                            <TextArea
                              placeholder={item.description}
                              allowClear
                              onChange={(e) => console.log(e.target.value)}
                              style={{ marginTop: "10px" }}
                              onChange={(e) =>
                                setupdatedDescription(e.target.value)
                              }
                            />
                          ) : (
                            item.description
                          )}
                        </Row>
                        <br />
                        <ReactPlayer
                          title="Play Video"
                          className="player"
                          width="100%"
                          url={
                            "https://generalvideos.pulse.stream/nitrous.mp4.m3u8"
                          }
                          playing={true}
                          controls={true}
                          light
                        />
                      </Card>{" "}
                    </Col>
                  );
                })}
                <Card
                  className="shadow-sm"
                  bodyStyle={{ padding: "14px", paddingBottom: "6px" }}
                  width="100%"
                  style={{
                    borderRadius: "8px",
                    textAlign: "center",
                    margin: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setAddCourseModalVisibility(true);
                    console.log(fileFolderData);
                  }}
                >
                  <b>Add a new chapter</b>
                  <div>
                    <Icon
                      type="plus-circle"
                      style={{ fontSize: "50px", marginTop: "10px" }}
                    />
                  </div>
                </Card>
              </Row>
            </>
          ) : (
            <>
              {existingCourses.length > 0
                ? existingCourses.map((item, i) => {
                    return (
                      <div>
                        <Card
                          bodyStyle={{ padding: "14px", paddingBottom: "6px" }}
                          className="shadow-sm"
                          style={{
                            borderRadius: "8px",
                            border: "none",
                            marginTop: "8px",
                            marginBottom: "10px",
                          }}
                        >
                          <Row>
                            <Col span={2}>
                              {" "}
                              <img
                                src={require("../img/existing_course.svg")}
                                width="37px"
                              />
                            </Col>
                            <Col span={16}>
                              {" "}
                              <div
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                {item.name}
                              </div>
                            </Col>
                            <Col span={5}>
                              {" "}
                              <div
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                Chapters ({item.chapters.length})
                              </div>
                            </Col>
                            <Col span={1}>
                              <Tooltip title="View/Manage">
                                <Icon
                                  type="setting"
                                  style={{
                                    fontSize: "18px",
                                    marginTop: "14.5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    setmanageFlag(true);
                                    setselectedCourse(item);
                                    console.log("manage");
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    );
                  })
                : ""}
            </>
          )}
        </Col>
      </Row>
      {/* Add new chapter model */}
      <Modal
        footer={null}
        destroyOnClose
        title="Add chapters to course"
        visible={addCourseModalVisibility}
        onCancel={(e) => setAddCourseModalVisibility(false)}
      >
        <div style={{ padding: "0px 20px" }}>
          {!chapterSelectedFlag ? (
            <>
              {" "}
              <div style={{ padding: "20px" }}>
                <Input
                  size="large"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="search with video name or folder name..."
                  suffix={<Icon type="search" style={{ paddingTop: "9px" }} />}
                />
              </div>{" "}
              {filteredFiles.map((item, index) => {
                if (item.file_type === "video") {
                  return (
                    <>
                      <Card
                        hoverable
                        title={
                          <>
                            <img
                              //   style={{ marginTop: "-5px" }}
                              src={require(`../img/file-types/${item.file_type}.png`)}
                              alt=""
                              width="20px"
                            />
                            &nbsp;&nbsp;{item.name}
                          </>
                        }
                        onClick={(e) => {
                          // addtoCourse(item);
                          console.log("kkkkkkk");
                          console.log(item);
                          setchapterSelectedFlag(true);
                          setselectedChapter(item);
                        }}
                        bodyStyle={{
                          padding: "10px",
                          backgroundColor: "#F5F5F5",
                        }}
                        //   headStyle={{ padding: "5px 8px" }}
                        style={{
                          margin: "20px 0px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <Meta
                          description={
                            <>
                              <Icon type="home" style={{ fontSize: "12px" }} />
                              &nbsp;&nbsp;{item.path}
                            </>
                          }
                        />
                      </Card>
                    </>
                  );
                }
              })}
            </>
          ) : (
            <>
              <div style={{ fontSize: "16px", fontWeight: 600 }}>
                Selected Video: &nbsp;&nbsp;{selectedChapter.path}
                {selectedChapter.name}
              </div>
              <br />

              <div
                style={{
                  backgroundImage: `url(${courseCover})`,
                  backgroundSize: "cover",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderStyle: courseCover ? "none" : "dashed",
                  borderWidth: "1px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              >
                <label for="image">
                  <input
                    accept="image/*"
                    // value={coverImage}
                    // onChange={e =>
                    //   setCoverImage(URL.createObjectURL(e.target.files[0]))
                    // }
                    onChange={onCoverImageChange}
                    type="file"
                    name="image"
                    id="image"
                    style={{ display: "none" }}
                  />
                  <Icon
                    type="camera"
                    style={{
                      fontSize: "40px",
                      display: courseCover ? "none" : "block",
                      color: "lightgray",
                    }}
                  />
                  {/* <Avatar
                    style={{
                      backgroundColor: "white",
                      zIndex: "0",
                      color: "black",
                      margin: "20px",
                      opacity: 1,
                      cursor: "pointer",
                      marginTop: "-30px"
                    }}
                    icon="camera"
                    size={80}
                  /> */}
                </label>
              </div>
              <Input
                type="text"
                value={chapterTitle}
                onChange={(e) => setChapterTitle(e.target.value)}
                placeholder="Title"
              ></Input>
              <br />
              <br />
              <Input
                type="text"
                value={chapterSubtitle}
                onChange={(e) => setChapterSubtitle(e.target.value)}
                placeholder="Sub-Title"
              ></Input>
              <br />
              <br />
              <TextArea
                type="text"
                value={chapterDescription}
                onChange={(e) => setChapterDescription(e.target.value)}
                placeholder="Description"
              ></TextArea>

              <br />
              {chapterTitle.length === 0 ||
              chapterSubtitle.length === 0 ||
              chapterDescription.length === 0 ? (
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "lightgray",
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  ** All fields are mandatory **
                </span>
              ) : (
                <br />
              )}

              <Button
                type="primary"
                block
                disabled={
                  chapterTitle.length === 0 ||
                  chapterSubtitle.length === 0 ||
                  chapterDescription.length === 0
                    ? true
                    : false
                }
                style={{ marginTop: "10px" }}
                onClick={(e) => addChapter()}
              >
                <b>Add chapter</b>
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ManageCourse;
