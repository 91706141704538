const url = "https://comms.globalxchange.io/";
// url: "https://btr-exchange.herokuapp.com/"
// const changelly="https://cryptopurchasingagent.herokuapp.com/"
const changelly = "https://cryptopurchasingagent.herokuapp.com/";
//if (process.env.NODE_ENV === "production") {
if ("fly" === "fly") {
  module.exports = {
    url,
    changelly,
  };
} else {
  module.exports = {
    url: "http://localhost:7000/",
  };
}
