import React, { useContext, useState, useEffect } from "react";
import {
  Menu,
  Icon,
  Divider,
  Row,
  Card,
  Col,
  Input,
  Button,
  message,
  Modal,
  Progress,
  Result,
  Drawer,
} from "antd";
import { FileContext } from "../Context";
import FileDownload from "js-file-download";
import axios from "axios";
import Loader from "react-loader-spinner";
import Iframe from "react-iframe";
// import queryString from "query-string";

const FileShare = (props) => {
  const value = useContext(FileContext);
  const [password, setPassword] = useState("");
  const [currentLink, setCurrentLink] = useState("");
  const [currentFilename, setCurrentFilename] = useState("");
  const [currentFile, setCurrentFile] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadProgressVisible, setDownloadProgressVisible] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [downloadingFile, setDownloadingFile] = useState("");
  const [downloadFinished, setDownloadFinished] = useState(false);
  const [accessError, setAccessError] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const [visibleFileViewer, setVisibleFileViewer] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [visibleImageViewer, setVisibleImageViewer] = useState(false);

  const checkPassword = () => {
    console.log(password);
  };

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    console.log(query.get("link"));
    setCurrentLink(query.get("link"));

    axios
      .get(
        value.url +
          `gxshare/file-details/?link=${query.get(
            "link"
          )}&s79741c8025f2e1de02327cadb0aa2277s=${query.get(
            "s79741c8025f2e1de02327cadb0aa2277s"
          )}&email=${value.email}&token=${value.token}`
      )
      .then((res) => {
        // FileDownload(res.data, item.name);
        console.log(res.data);
        setPageLoaded(true);
        if (res.data.status) {
          setCurrentFilename(res.data.payload.name);
          setCurrentFile(res.data.payload);
        } else {
          setAccessError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.responderEnd);
        }
      });
  }, []);

  const downloadClick = () => {
    const query = new URLSearchParams(props.location.search);
    // axios
    //   .get(
    //     value.url +
    //       `gxshare/?link=${query.get(
    //         "link"
    //       )}&s79741c8025f2e1de02327cadb0aa2277s=${query.get(
    //         "s79741c8025f2e1de02327cadb0aa2277s"
    //       )}`
    //   )
    //   .then(res => {
    //     FileDownload(res.data, currentFilename);
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       console.log(error.responderEnd);
    //     }
    //   });

    setDownloadProgressVisible(true);
    setDownloadingFile(currentFilename);
    axios({
      method: "get",
      url:
        value.url +
        `gxsharepublic/?link=${query.get(
          "link"
        )}&s79741c8025f2e1de02327cadb0aa2277s=${query.get(
          "s79741c8025f2e1de02327cadb0aa2277s"
        )}`,
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTotalFileSize(progressEvent.total / 10 ** 6);
        setDownloadProgress(percentCompleted);
        // console.log(progressEvent.lengthComputable);
        console.log(percentCompleted);
      },
    })
      .then((res) => {
        if (res.data && res.data.status && !res.data.status) {
          message.destroy();
          message.error("File Download Error", 3);
          setDownloadProgress(0);
          setTimeout(() => {
            setDownloadProgressVisible(false);
            setTotalFileSize(0);
            setDownloadingFile("");
            setDownloadFinished(false);
          }, 5000);
        } else {
          console.log(res);
          setTimeout(() => {
            setDownloadFinished(true);
          }, 500);

          FileDownload(res.data, currentFilename);
          setTimeout(() => {
            setDownloadProgress(0);
            setDownloadProgressVisible(false);
            setTotalFileSize(0);
            setDownloadingFile("");
            setDownloadFinished(false);
          }, 5000);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };
  const viewImage = () => {
    const query = new URLSearchParams(props.location.search);
    setImgUrl(
      value.url +
        `gxsharepublic/?link=${query.get(
          "link"
        )}&s79741c8025f2e1de02327cadb0aa2277s=${query.get(
          "s79741c8025f2e1de02327cadb0aa2277s"
        )}`
    );
    setVisibleImageViewer(true);
  };

  const conditionalShowFiles = () => {
    if (currentFilename.length > 0) {
      if (currentFile.file_type === "pdf") {
        return (
          <div style={{ display: "flex" }}>
            <Button
              size="large"
              onClick={downloadClick}
              block
              style={{ fontSize: "20px" }}
            >
              <Icon type="cloud-download" /> Download File
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              size="large"
              onClick={(e) => setVisibleFileViewer(true)}
              block
              style={{ fontSize: "20px" }}
            >
              <Icon type="eye" /> View File
            </Button>
          </div>
        );
      } else if (currentFile.file_type === "image") {
        return (
          <div style={{ display: "flex" }}>
            <Button
              size="large"
              onClick={downloadClick}
              block
              style={{
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon type="cloud-download" /> <span>Download Image</span>
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              size="large"
              onClick={viewImage}
              block
              style={{
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon type="eye" /> <span>View Image</span>
            </Button>
          </div>
        );
      } else if (currentFile.file_type === "doc") {
        return (
          <div style={{ display: "flex" }}>
            <Button
              size="large"
              onClick={downloadClick}
              block
              style={{ fontSize: "20px" }}
            >
              <Icon type="cloud-download" /> Download Document
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              size="large"
              onClick={(e) => setVisibleFileViewer(true)}
              block
              style={{ fontSize: "20px" }}
            >
              <Icon type="eye" /> View Document
            </Button>
          </div>
        );
      }
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${require("../img/white_bg.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row>
          <div>
            <img src={require("../img/gx_blue.svg")} width="100px" />
          </div>
        </Row>
        <div style={{ fontSize: "30px", fontWeight: "bold", marginTop: "5px" }}>
          Brainshare
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row>
          <Card
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "10px",
              margin: "20px",
            }}
            className="shadow"
            bodyStyle={{}}
          >
            {pageLoaded ? (
              accessError ? (
                <div>
                  {/* <Result
                  status="error"
                  title="File Not Exists or Inaccesible"
                  subTitle="Please re-check the link or "
                /> */}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      //   marginTop: "40px",
                      flexDirection: window.innerWidth > 600 ? "row" : "column",
                    }}
                  >
                    <div>
                      <img
                        style={{ marginTop: "-5px" }}
                        src={require(`../img//file-error.png`)}
                        alt=""
                        width="130px"
                      />
                    </div>
                    <div
                      style={{
                        textAlign: window.innerWidth < 600 ? "center" : "left",
                        margin: "20px 0px",
                      }}
                    >
                      <span style={{ fontSize: "23px", fontWeight: "bold" }}>
                        File not Exists or Inaccesible.......
                      </span>
                      <br />
                      <span style={{ fontSize: "18px", marginTop: "10px" }}>
                        Please re-check the link or Ask the file owner for
                        access.
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    Download File ({currentFilename})
                  </div>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      marginTop: "10px",
                    }}
                  >
                    This file is shared using GX-Share which is securely
                    end-to-end encrypted.
                  </div>
                  {/* <form onSubmit={checkPassword}>
                <Input.Password
                  size="large"
                  placeholder="Enter password to unlock the file"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </form> */}
                  <br />
                  {conditionalShowFiles()}
                </div>
              )
            ) : (
              <div
                style={{
                  marginTop: "50%",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#186ab4"
                  height={100}
                  width={100}
                />
              </div>
            )}
          </Card>
        </Row>
      </div>
      <Modal
        destroyOnClose
        footer={null}
        visible={downloadProgressVisible}
        // onOk={this.handleOk}
        onCancel={(e) => {
          setDownloadProgressVisible(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <>
            {" "}
            <Progress type="circle" percent={downloadProgress} />
            <span
              style={{
                marginTop: "20px",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Name: {downloadingFile}
            </span>
            <span
              style={{
                marginTop: "5px",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Size: {totalFileSize.toFixed(3)}MB{" "}
              {/* <small>
              <i>PublicLinkroximately</i>
            </small> */}
            </span>
          </>

          {downloadFinished ? (
            <span
              style={{ marginTop: "20px", fontSize: "15px", fontWeight: "500" }}
            >
              Saving file... Check Downloads section in browser.
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal>

      {/* Pdf Viewer */}
      <Drawer
        style={{ zIndex: "999999999999999999999999" }}
        width="100vw"
        // closable={true}
        // title="Basic Modal"
        visible={visibleFileViewer}
        onClose={(e) => setVisibleFileViewer(false)}
        // footer={null}
        bodyStyle={{ padding: "0px" }}
      >
        {currentFile.file_type === "pdf" ? (
          <div style={{ height: "90vh" }}>
            <Iframe
              url={
                value.url +
                `gxsharepublic/?link=${new URLSearchParams(
                  props.location.search
                ).get(
                  "link"
                )}&s79741c8025f2e1de02327cadb0aa2277s=${new URLSearchParams(
                  props.location.search
                ).get("s79741c8025f2e1de02327cadb0aa2277s")}`
              }
              width="100%"
              id="myId"
              className="iframe-style"
              display="initial"
              position="relative"
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Iframe
              class="doc"
              width="830"
              height="600"
              frameborder="0"
              src={`https://docs.google.com/gview?url=${
                value.url +
                `gxsharepublic/?link=${new URLSearchParams(
                  props.location.search
                ).get("link")}
                &s79741c8025f2e1de02327cadb0aa2277s=${new URLSearchParams(
                  props.location.search
                ).get("s79741c8025f2e1de02327cadb0aa2277s")}`
              }&embedded=true`}
            ></Iframe>
          </div>
        )}
      </Drawer>

      {/* View Image */}
      <Modal
        centered
        width="90%"
        // title="full image"
        footer={null}
        visible={visibleImageViewer}
        onCancel={(e) => setVisibleImageViewer(false)}
      >
        <img src={imgUrl} alt="" width="100%" />
      </Modal>
    </div>
  );
};

export default FileShare;
