import React, { useContext, useState, useEffect } from "react";
import Viewer from "react-viewer";
import ReactPlayer from "react-player";
import { Button, Modal } from "antd";
import axios from "axios";
import FileDownload from "js-file-download";
import PDFViewer from "mgr-pdf-viewer-react";

const ImageViewer = props => {
  const [videourl, setvideourl] = useState("");
  const [pdfurl, setpdfurl] = useState("");
  const [pdfenable, setpdfenable] = useState(false);
  const [videoenable, setvideoenable] = useState(false);
  const [imageenable, setimageenable] = useState(false);

  const downloadVideo = () => {
    axios({
      method: "get",
      url: "http://localhost:3232/" + "testing/video-test",
      responseType: "blob"
    })
      .then(res => {
        console.log(res);
        const file = new Blob([res.data], {
          type: "application/video"
        });
        console.log(URL.createObjectURL(file));
        //Build a URL from the file
        setvideourl(URL.createObjectURL(file));
        setvideoenable(true);

        // FileDownload(res.data, "test.mkv");
      })
      .catch(error => {
        if (error) {
          console.log(error);
        }
      });
  };

  const downloadPdf = () => {
    axios({
      method: "get",
      url: "http://localhost:3232/" + "testing/pdf-test",
      responseType: "blob"
    })
      .then(res => {
        console.log(res);
        const file = new Blob([res.data], {
          type: "application/pdf"
        });
        console.log(URL.createObjectURL(file));
        //Build a URL from the file
        setpdfurl(URL.createObjectURL(file));
        setpdfenable(true);

        // FileDownload(res.data, "test.pdf");
      })
      .catch(error => {
        if (error) {
          console.log(error);
        }
      });
  };
  return (
    <div>
      <br />
      <br />
      {/* image functionality */}
      <div>
        <Button
          onClick={() => {
            setimageenable(true);
          }}
        >
          Show Images
        </Button>
        <br />
        <Viewer
          visible={imageenable}
          onClose={() => {
            setimageenable(false);
          }}
          images={[
            {
              src:
                "http://hdwpro.com/wp-content/uploads/2017/01/Car-Full-HD-Wallpaper.jpg",
              alt: ""
            }
          ]}
        />
      </div>
      <br />
      {/* video Functionality */}
      <Button onClick={downloadVideo}>Play Video</Button>
      <br />
      <div>
        <Modal
          centered
          closable={false}
          // title="Basic Modal"
          visible={videoenable}
          onCancel={e => setvideoenable(false)}
          footer={null}
          bodyStyle={{ padding: "0px" }}
        >
          <ReactPlayer url={videourl} playing controls={true} />
        </Modal>
      </div>
      <br />

      {/* pdf functionality */}
      <Button onClick={downloadPdf}>View PDF</Button>

      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={pdfenable}
        onCancel={e => setpdfenable(false)}
        footer={null}
        bodyStyle={{ padding: "0px" }}
      >
        <PDFViewer
          document={{
            url: pdfurl
          }}
        />
      </Modal>
    </div>
  );
};

export default ImageViewer;
